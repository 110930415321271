import React, { useEffect, useState } from "react";
import { Box, SimpleGrid, useColorMode } from "@chakra-ui/react";
import "./Projects.css";
import clockify from "../Images/clockify.png";
import rymo from "../Images/rymo.webp";
import urban from "../Images/landing.png";
import ndtv from "../Images/ndtv.jpg";
import zoomcar from "../Images/zoomcar.jpg";
import portfolio from "../Images/my_portfolio.png";
import weather_app from "../Images/weather_app.png";
import chat_app from "../Images/chat_app.png";
import tata1mg from "../Images/tata1mg.png";
import youtube from "../Images/youtube.png";
import language from "../Images/language.png";
import SmartBuy from "../Images/SmartBuy.jpeg";
import { FaGithub, FaEye } from "react-icons/fa";

export const Projects = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const project_Data = [
    {
      image: SmartBuy,
      name: "Smart Buy",
      description:
        "It's my major project where I've utilized the MERN stack to develop a fully functional website complete with backend integration and payment gateway integration, encompassing all the features expected of an e-commerce platform.",
      // "It's my major project where I've used MERN stack to make the full functional website along with backend integration and payment gateway integration with all the features of an e-commerce website.",
      tech_stack:
        " React, Redux, JavaScript, CSS, Chakra UI, Node, Express, MongoDB, Razorpay",
      github_link: "https://github.com/sonuprasad66/SmartBuy",
      deployed_link: "https://smartbuy.sonuprasad.in/",
    },
    {
      image: chat_app,
      name: "Chatting App",
      description:
        "I created an advanced Chat Application with real-time functionality. It leverages React.js, Socket.io, and Node.js, ensuring a seamless chatting experience with enhanced security and customization options.",
      tech_stack:
        " React, Node, Express, MongoDB, WebSocket, Styled-Components, CSS, JavaScript",
      github_link: "https://github.com/sonuprasad66/Real_Time_Chat_App",
      deployed_link: "https://whatsapp.sonuprasad.in/",
    },
    {
      image: weather_app,
      name: "Weather App",
      description:
        "It's a web app to show the weather forecast details using weather API & here you will be able to search for all the weather details for any city in the world & it also detects your current location show the weather data.",
      tech_stack:
        " React, Redux, Chakra UI, CSS, JavaScript, Rest API                                             ",
      github_link: "https://github.com/sonuprasad66/Weather_App",
      deployed_link: "https://weather.sonuprasad.in/",
    },
    {
      image: tata1mg,
      name: "Tata 1mg Clone",
      description:
        "TATA 1mg.com is an e-commerce web application that widely provides e-pharmacy, diagnostics, e-consultation, and healthcare solutions related service all across India.",
      tech_stack:
        " React, Redux, JavaScript, CSS, Chakra UI, Node, Express, MongoDB",
      github_link: "https://github.com/sonuprasad66/omniscient-sheet-6598",
      deployed_link: "https://tata-1mg-clone-sage.vercel.app/",
    },
    {
      image: urban,
      name: "Urban Company",
      description:
        "This website is a clone of 'Urban Company' I implimented some functionalities like login & signup for the authentication, add services, buy services, blog page, etc.",
      tech_stack:
        " React, Redux, JavaScript, API, CSS, Chakra UI, Font-Awesome",
      github_link: "https://github.com/sonuprasad66/tense-visitor-349",
      deployed_link: "https://urban-company-ruddy.vercel.app/",
    },
    {
      image: clockify,
      name: "Clockify Clone",
      description:
        " Clockify is one of the best Time tracking websites. On this website, you can easily track your time and manage also. A individual project built by me executed in 5 days.",
      tech_stack:
        " React, JavaScript, Chakra UI, CSS, React Icons, Font-Awesome ",
      github_link: "https://github.com/sonuprasad66/brief-shirt-7318",
      deployed_link: "https://snazzy-semifreddo-553037.netlify.app/",
    },
    {
      image: language,
      name: "Language Translator App",
      description:
        "Building a web app for language translation using language translator API. Here you will be able to translate between multiple languages.",
      tech_stack: " React, HTML, CSS, JavaScript, Language-API, Font-Awesome",
      github_link: "https://github.com/sonuprasad66/Language_Translator_App",
      deployed_link: "https://sonu-language-translator-app.vercel.app/",
    },
    {
      image: youtube,
      name: "YouTube Clone",
      description:
        "This website is a clone of 'YouTube' I implimented some functionalities like user can search any types of videos and channel name also he can play video.",
      tech_stack: " HTML, CSS, JavaScript, YouTube-API, Font-Awesome",
      github_link: "https://github.com/sonuprasad66/YouTube_Clone",
      deployed_link: "https://taupe-lily-fd39d4.netlify.app/",
    },
    {
      image: rymo,
      name: "Rymo Clone",
      description:
        "This website is a clone of 'Rymo.com' which is an e-commerce store & here I implimented some functionalities like add to cart etc.",
      tech_stack: " HTML, CSS, JavaScript, Boostrap, Font-Awesome",
      github_link: "https://github.com/sonuprasad66/Rymo_Clone",
      deployed_link: "https://rad-kelpie-75b4db.netlify.app/index.html",
    },

    /*
    {
      image: ndtv,
      name: "Ndtv Clone",
      description:
        "This website is a clone of 'Ndtv.com' which is a news provider company here you can see all types of news. I implemented some pages like shopping portal etc.",
      tech_stack: "HTML, CSS, JavaScript.",
      github_link: "https://github.com/rsoni2843/ndtv",
      deployed_link: "https://resilient-rolypoly-319f18.netlify.app/",
    },
    {
      image: zoomcar,
      name: "ZoomCar Clone",
      description:
        "This website is a clone of 'ZoomCar.com' which is a car provided company here you can book car for traveling one place to another place.",
      tech_stack: "HTML, CSS, Advance-JavaScript, Font Awasome",
      github_link: "https://github.com/Abiramirajasekar/ZoomCar",
      deployed_link: "https://relaxed-axolotl-1b3408.netlify.app/",
    },
    {
      image: portfolio,
      name: "My Portfolio",
      description:
        "This is my portfolio which I recently made using React, JavaScript, CSS and Chakra UI.",
      tech_stack: "React, JavaScript, Chakra UI, CSS, React Icons",
      github_link:
        "https://github.com/sonuprasad66/sonuprasad66.github.io/tree/master",
      deployed_link: "https://sonuprasad66.github.io/",
    },
     */
  ];

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    setProjectData(project_Data);
  }, []);

  return (
    <div
      id="project"
      className="project_main_container"
      style={{
        backgroundColor: colorMode == "light" ? "#edf2f8" : "#1a202c",
      }}
    >
      <div className="project_container">
        <div className="project_heading">
          <h2>
            <span>My</span> Creative <span>Projects </span>Section
          </h2>
        </div>

        <div className="project_grid">
          <SimpleGrid columns={[1, 1, 2, 3]} spacing="50px">
            {projectData?.map((res) => (
              <Box key={res.name}>
                <div
                  className="project_box"
                  style={{
                    backgroundColor: colorMode == "light" ? "white" : "#1a202c",
                  }}
                >
                  <div className="project_image">
                    <img src={res.image} alt="SmartBuy" />
                  </div>

                  <div className="project_name">
                    <h2>{res.name}</h2>
                  </div>
                  <div className="project_desc">
                    <p>{res.description}</p>
                  </div>
                  <div className="project_tech_stack">
                    <h5>
                      <span>Tech Stack :</span>
                      {res.tech_stack}
                    </h5>
                  </div>
                  <div className="project_icon">
                    <div className="github_link">
                      <a
                        href={res.github_link}
                        rel="noopener norefferer"
                        target="_blank"
                      >
                        <FaGithub />
                      </a>
                    </div>
                    <div className="deploy_link">
                      <a
                        href={res.deployed_link}
                        rel="noopener norefferer"
                        target="_blank"
                      >
                        <FaEye />
                      </a>
                    </div>
                  </div>
                </div>
              </Box>
            ))}
          </SimpleGrid>
        </div>
      </div>
    </div>
  );
};
