import React from "react";
import "./About.css";
import { Box } from "@chakra-ui/react";

export const AboutMe = () => {
  return (
    <Box>
      <ul>
        <li>
          Hello! My name is Sonu Prasad & I enjoy creating things that live on
          the internet. I'm a passionate Developer, with strong administrative &
          communication skills, good attention to detail & the ability to write
          efficient code.
        </li>
        <li>
          After completing my Full-Stack Web Development course, I got the
          opportunity to become as a Full Stack Developer at Empplan Sales
          Promotion Software. There, I developed a user-friendly dashboard using
          React.js to provide easy access to critical client data.
        </li>
        <li>
          Fast Forwarding to today, I am working as a Full Stack Developer at
          Whitecrow spin-off giant 'Webbtree'. After much preparation,
          dedication and consistency, I am able to achieve this milestone. As I
          begin the next chapter of my career, I am very eager to Learn, Grow
          and Contribute to my new role.
        </li>
      </ul>
    </Box>
  );
};
