import { Box, Img } from "@chakra-ui/react";
import profile_rgb from "../Images/profile/sonu_removebg.png"
import profile_cover from "../Images/profile/profilecover.png"

function ProfileSection(){
return (
    <>
     <Box position="relative">
        <Box  w="350px" h="350px">
         <Img src={profile_cover} alt="profilecover" />
        </Box>
        <Box position="absolute" top="-110px" right="-15px" w="300px" h="420px" className="profilePicture">
         <Img  src={profile_rgb} alt="profile"/>
        </Box>
    </Box>
    </> 
 )
};
export default ProfileSection;