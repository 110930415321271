import React from "react";
import "./About.css";
import { ListItem, UnorderedList, Box } from "@chakra-ui/react";

export const ExpWhiteCrow = () => {
  return (
    <Box>
      <Box className="exp-company">Full Stack Developer at Whitecrow</Box>
      <Box className="exp-date">Sep 2023 - Present</Box>
      <Box className="exp-project">Project: Talent Acquisition Management</Box>
      <Box>
        <UnorderedList>
          <ListItem>
            Developed a user-friendly website using MERN and MEAN Stack,
            ensuring easy access to candidate data.
          </ListItem>
          <ListItem>
            Created Restful APIs for smooth extraction and manipulation of
            candidate data, improving website functionality.
          </ListItem>
          <ListItem>
            Executed data manipulation tasks, meeting specific format
            requirements for candidate data.
          </ListItem>
          <ListItem>
            Converted UI/UX team-provided designs into high-quality code,
            enhancing the overall user experience.
          </ListItem>
          <ListItem>
            Optimized API's performance across plateform and browsers,
            implementing efficiency strategies.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};
