import React from "react";
import "./About.css";
import { ListItem, UnorderedList, Box } from "@chakra-ui/react";

export const ExpEmpplan = () => {
  return (
    <Box>
      <Box className="exp-company">Full Stack Developer at Empplan</Box>
      <Box className="exp-date">Jan 2023 - Aug 2023</Box>
      <Box className="exp-project">Project: Clients Internal Dashboard</Box>
      <Box>
        <UnorderedList>
          <ListItem>
            Developed a user-friendly dashboard using React.js, ensuring easy
            access to critical client data.
          </ListItem>
          <ListItem>
            Created Restful APIs for smooth extraction and manipulation of
            client data, improving dashboard functionality.
          </ListItem>
          <ListItem>
            Executed data manipulation tasks, meeting specific format
            requirements for client data.
          </ListItem>
          <ListItem>
            Developed a good user-friendly interface dashboard to efficiently
            manage client data.
          </ListItem>
          <ListItem>
            Optimized dashboard performance across devices and browsers,
            implementing efficiency strategies.
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};
